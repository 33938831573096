<template>
  <div class="pxkeji-right-box" style="margin-top: 0">
    <a-form class="ant-advanced-search-form">
      <a-row :gutter="24">
        <a-col :span="8">
          <a-form-item label="考试名称">
            <a-input placeholder="请输入" v-model="where.keyword.testName" />
          </a-form-item>
        </a-col>

        <a-col :span="4" :style="{ textAlign: 'left' }">
          <a-form-item>
            <a-button type="primary" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-table
      rowKey="id"
      :columns="list.columns"
      :pagination="list.pagination"
      @change="onPageChange"
      :dataSource="list.datas"
      :loading="isloading"
      bordered
      :rowSelection="{
        type: 'checkbox',
        columnWidth: 40,
        onChange: onRowChange,
        selectedRowKeys: selectRows,
        getCheckboxProps: getCheckboxProps,
      }"
      size="small"
      :locale="{ emptyText: '暂无数据' }"
      :scroll="{ y: list.tableHeight }"
    >
    </a-table>
    <a-button type="primary" :style="{ margin: '10px' }" @click="choseBack">
      选择并返回
    </a-button>
  </div>
</template>

<script>
var that;
const columns = [
  {
    dataIndex: "testName",
    align: "center",
    title: "考试名称",
    ellipsis: true,
  },
  {
    dataIndex: "testName",
    align: "center",
    title: "考试类型",
    ellipsis: true,
  },
  {
    dataIndex: "questionsCount",
    align: "center",
    title: "题目数量",
  },
  {
    dataIndex: "passLine",
    align: "center",
    title: "及格分数",
  },
];
export default {
  props: {
    ids: Array,
  },
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          defaultCurrent: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 293,
      },
      where: {
        keyword: {
          testName: "",
          examStatus: "1",
        },
      },
      allSelectRows: [],
      selectRows: [], //选中项编号
      isloading: false,
    };
  },
  mounted() {
    that = this;
    if (window.innerWidth >= 1600) {
      that.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      that.list.tableHeight = window.innerHeight - 293;
      that.innerWidth = window.innerWidth;
      if (window.innerWidth >= 1600) {
        that.list.pagination.pageSize = 20;
      } else {
        that.list.pagination.pageSize = 10;
      }
    };
    that.$nextTick(() => {
      that.allSelectRows = that.ids ? that.ids : [];
      that.GetList();
    });
  },
  methods: {
    getCheckboxProps(record) {
      let checked = false;
      for (let i in that.allSelectRows) {
        if (that.allSelectRows[i] == record.id) {
          checked = true;
          that.selectRows.push(that.allSelectRows[i]);
          break;
        }
      }
      return {
        props: {
          defaultChecked: checked,
          disabled: checked,
        },
      };
    },

    GetList() {
      that.isloading = true;
      that.$api.examination
        .getPageList(
          that.list.pagination.defaultCurrent,
          that.list.pagination.pageSize,
          that.where.keyword.testName,
          that.where.keyword.examStatus
        )
        .then((res) => {
          if (res.errorCode == that.$msg.responseCode.success) {
            that.list.datas = res.data.records;
            that.list.pagination.total = Number(res.data.total);
          } else {
            that.$message.error(res.errorMsg);
          }
          that.isloading = false;
        })
        .catch(() => {
          that.isloading = false;
        });
    },
    onPageChange(page) {
      that.list.pagination.defaultCurrent = page.current;
      that.list.pagination.pageSize = page.pageSize;
      that.GetList();
    },
    //table 单选
    onRowChange(selectedRowKeys) {
      that.selectRows = selectedRowKeys;
      for (let i in that.list.datas) {
        if (selectedRowKeys.indexOf(that.list.datas[i].id) != -1) {
          //选中
          let find = false;
          for (let sel in that.allSelectRows) {
            if (that.allSelectRows[sel] == that.list.datas[i].id) {
              find = true;
              break;
            }
          }

          if (!find) {
            that.allSelectRows.push(that.list.datas[i].id);
          }
        } else {
          for (let sel in that.allSelectRows) {
            if (that.allSelectRows[sel] == that.list.datas[i].id) {
              that.allSelectRows.splice(sel, 1);
              break;
            }
          }
        }
      }
    },
    searchList() {
      for (let key in that.allSelectRows) {
        delete that.allSelectRows[key];
      }
      that.list.pagination.defaultCurrent = 1;
      that.GetList();
    },
    //选择并返回
    choseBack() {
      if (that.allSelectRows.length == 0) {
        that.$message.error("请选择考试");
        return;
      }
      that.$emit("callbackMethod", that.allSelectRows.join(","));
    },
  },
};
</script>
<style>
</style>