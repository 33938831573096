<template>
  <div class="mainWrapper clearfix">
    <div class="articleMeauleft">
      <a-divider orientation="left">课程分类</a-divider>
      <a-tree
        :replaceFields="replaceFields"
        :treeData="classList"
        @select="selectTree"
      />
    </div>
    <div class="articleMainRight">
      <div>
        <a-form class="ant-advanced-search-form">
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="课程名称">
                <a-input
                  placeholder="请输入"
                  v-model="where.keyword.courseName"
                />
              </a-form-item>
            </a-col>
            <a-col :span="4" :style="{ textAlign: 'left' }">
              <a-form-item>
                <a-button type="primary" @click="searchList">搜索</a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div style="margin-top: 10px">
          <a-table
            rowKey="id"
            :columns="list.columns"
            :pagination="list.pagination"
            @change="onPageChange"
            :dataSource="list.datas"
            :loading="isloading"
            bordered
            :rowSelection="{
              type: 'checkbox',
              columnWidth: 40,
              onChange: onRowChange,
              selectedRowKeys: selectRows,
              getCheckboxProps: getCheckboxProps,
            }"
            size="small"
            :locale="{ emptyText: '暂无数据' }"
            :scroll="{ y: list.tableHeight }"
          >
          </a-table>
          <a-button
            type="primary"
            :style="{ margin: '10px' }"
            @click="choseBack"
          >
            选择并返回
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var that;
const columns = [
  {
    dataIndex: "name",
    align: "left",
    title: "课程名称",
    ellipsis: true,
  },
  {
    dataIndex: "typeName",
    title: "所属分类",
    ellipsis: true,
  },
  {
    dataIndex: "courseCredit",
    align: "center",
    title: "学分",
    width: 80,
  },
  {
    dataIndex: "hourCount",
    align: "center",
    title: "学时",
    width: 80,
  },
];
export default {
  props: {
    ids: Array,
  },
  data() {
    return {
      classList: [],
      replaceFields: { children: "children", title: "name", key: "id" },
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          defaultCurrent: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 293,
      },
      where: {
        keyword: {
          courseName: "",
          typeId: null,
        },
      },
      allSelectRows: [],
      selectRows: [], //选中项编号
      isloading: false,
    };
  },
  mounted() {
    that = this;
    if (window.innerWidth >= 1600) {
      that.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      that.list.tableHeight = window.innerHeight - 293;
      that.innerWidth = window.innerWidth;
      if (window.innerWidth >= 1600) {
        that.list.pagination.pageSize = 20;
      } else {
        that.list.pagination.pageSize = 10;
      }
    };
    that.$nextTick(() => {
      that.allSelectRows = this.ids ? this.ids : [];
      that.getType();
      that.GetList();
    });
  },
  methods: {
    selectTree(selectedKeys) {
      that.where.keyword.typeId = selectedKeys[selectedKeys.length - 1];
      that.GetList();
    },
    getCheckboxProps(record) {
      let checked = false;
      for (let i in that.allSelectRows) {
        if (that.allSelectRows[i] == record.id) {
          checked = true;
          that.selectRows.push(that.allSelectRows[i]);
          break;
        }
      }
      return {
        props: {
          defaultChecked: checked,
          disabled: checked,
        },
      };
    },
    async getType() {
      let result = await that.$api.courseType.getUseTypeTree();
      if (result.errorCode == that.$msg.responseCode.success) {
        that.classList = result.data;
      } else {
        that.$message.error(result.errorMsg);
      }
    },
    GetList() {
      that.isloading = true;
      that.$api.course
        .getPageList(
          that.list.pagination.defaultCurrent,
          that.list.pagination.pageSize,
          0,
          that.where.keyword.typeId,
          that.where.keyword.courseName,
          1
        )
        .then((res) => {
          if (res.errorCode == that.$msg.responseCode.success) {
            that.list.datas = res.data.records;
            that.list.pagination.total = Number(res.data.total);
          } else {
            that.$message.error(res.errorMsg);
          }
          that.isloading = false;
        })
        .catch(() => {
          that.isloading = false;
        });
    },
    onPageChange(page) {
      that.list.pagination.defaultCurrent = page.current;
      that.list.pagination.pageSize = page.pageSize;
      that.GetList();
    },
    onRowChange(selectedRowKeys) {
      that.selectRows = selectedRowKeys;
      for (let i in that.list.datas) {
        if (selectedRowKeys.indexOf(that.list.datas[i].id) != -1) {
          //选中
          let find = false;
          for (let sel in that.allSelectRows) {
            if (that.allSelectRows[sel] == that.list.datas[i].id) {
              find = true;
              break;
            }
          }

          if (!find) {
            that.allSelectRows.push(that.list.datas[i].id);
          }
        } else {
          for (let sel in that.allSelectRows) {
            if (that.allSelectRows[sel] == that.list.datas[i].id) {
              that.allSelectRows.splice(sel, 1);
              break;
            }
          }
        }
      }
    },
    searchList() {
      for (let key in that.allSelectRows) {
        delete that.allSelectRows[key];
      }
      that.list.pagination.defaultCurrent = 1;
      that.GetList();
    },
    //选择并返回
    choseBack() {
      if (that.allSelectRows.length == 0) {
        that.$message.error("请选择课程");
        return;
      }
      that.$emit("callbackMethod", that.allSelectRows.join(","));
    },
  },
};
</script>
<style>
.mainWrapper {
  box-sizing: border-box !important;
}
.articleMeauleft {
  width: 20%;
  padding: 0 1%;
  min-height: 700px;
  background: #fff;
  float: left;
  margin-top: 24px;
}
.articleMainRight {
  width: 80%;
  float: right;
}
.courseClass {
  padding-left: 10px;
}
.courseClass span {
  font-size: 14px;
  line-height: 2.5;
  cursor: pointer;
}
</style>