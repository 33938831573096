var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pxkeji-right-box",staticStyle:{"margin-top":"0"}},[_c('a-form',{staticClass:"ant-advanced-search-form"},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"考试名称"}},[_c('a-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.where.keyword.testName),callback:function ($$v) {_vm.$set(_vm.where.keyword, "testName", $$v)},expression:"where.keyword.testName"}})],1)],1),_c('a-col',{style:({ textAlign: 'left' }),attrs:{"span":4}},[_c('a-form-item',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.searchList}},[_vm._v("搜索")])],1)],1)],1)],1),_c('a-table',{attrs:{"rowKey":"id","columns":_vm.list.columns,"pagination":_vm.list.pagination,"dataSource":_vm.list.datas,"loading":_vm.isloading,"bordered":"","rowSelection":{
      type: 'checkbox',
      columnWidth: 40,
      onChange: _vm.onRowChange,
      selectedRowKeys: _vm.selectRows,
      getCheckboxProps: _vm.getCheckboxProps,
    },"size":"small","locale":{ emptyText: '暂无数据' },"scroll":{ y: _vm.list.tableHeight }},on:{"change":_vm.onPageChange}}),_c('a-button',{style:({ margin: '10px' }),attrs:{"type":"primary"},on:{"click":_vm.choseBack}},[_vm._v(" 选择并返回 ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }