<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div class style="position: relative">
      <a-form-model
        ref="triggerForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="是否自动生成"
              prop="autoCreate"
              v-if="dataType == 2"
            >
              <a-switch
                checked-children="是"
                un-checked-children="否"
                v-model="info.autoCreate"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="是否自动审核"
              prop="autoAudit"
              v-if="dataType == 2"
            >
              <a-switch
                checked-children="是"
                un-checked-children="否"
                v-model="info.autoAudit"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="触发类型" prop="type">
              <a-select @change="typeChanged" v-model="info.type">
                <!-- <a-select-option :value="0">学分</a-select-option> -->
                <a-select-option :value="1">学时</a-select-option>
                <a-select-option :value="2">指定课程</a-select-option>
                <a-select-option :value="3" v-if="dataType == 2">
                  指定考试
                </a-select-option>
                <a-select-option :value="5" v-if="projectId == null">
                  任意课程
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="课程学分"
              v-if="info.type == 0"
              prop="value"
            >
              <a-input
                placeholder="请输入"
                v-model.number="info.value"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item
              label="课程学时"
              v-if="info.type == 1"
              prop="value"
            >
              <a-input
                placeholder="请输入"
                v-model.number="info.value"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item
          label="关联课程"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 20 }"
          v-if="info.type == 2"
        >
          <pxkeji-btn name="选择课程" type="primary" @click="selectCourse" />
          <a-table
            rowKey="id"
            size="small"
            bordered
            :columns="courses.columns"
            :pagination="false"
            :dataSource="courses.datas"
            :locale="{ emptyText: '暂无数据' }"
          >
            <template slot="action" slot-scope="info">
              <a-popconfirm
                title="是否确认移除此课程"
                @confirm="removeCourse(info.id)"
              >
                <a-icon slot="icon" type="question-circle-o" />
                <pxkeji-btn name="移除" size="small" type="danger" />
              </a-popconfirm>
            </template>
          </a-table>
        </a-form-model-item>
        <a-form-model-item
          label="关联考试"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 20 }"
          v-if="info.type == 3"
        >
          <pxkeji-btn name="选择考试" type="primary" @click="selectExam" />
          <a-table
            rowKey="id"
            size="small"
            bordered
            :columns="examinations.columns"
            :pagination="false"
            :dataSource="examinations.datas"
            :locale="{ emptyText: '暂无数据' }"
          >
            <template slot="action" slot-scope="info">
              <a-popconfirm
                title="是否确认移除此考试"
                @confirm="removeExam(info.id)"
              >
                <a-icon slot="icon" type="question-circle-o" />
                <pxkeji-btn name="移除" size="small" type="danger" />
              </a-popconfirm>
            </template>
          </a-table>
        </a-form-model-item>
        <a-form-model-item
          label="课程范围"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 20 }"
          v-if="info.type == 0 || info.type == 1 || info.type == 5"
        >
          <pxkeji-btn name="选择课程" type="primary" @click="selectCourse" />
          <a-table
            rowKey="id"
            size="small"
            bordered
            :columns="courses.columns"
            :dataSource="courses.datas"
            :pagination="false"
            :locale="{ emptyText: '暂无数据' }"
          >
            <template slot="action" slot-scope="info">
              <a-popconfirm
                title="是否确认移除此课程"
                @confirm="removeCourse(info.id)"
              >
                <a-icon slot="icon" type="question-circle-o" />
                <pxkeji-btn name="移除" size="small" type="danger" />
              </a-popconfirm>
            </template>
          </a-table>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 5, offset: 4 }">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-modal
      title="选择课程"
      v-model="selCourse.visible"
      width="1200px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <ChooseCourse @callbackMethod="closeCourse" :ids="selCourse.courseIds" />
    </a-modal>
    <a-modal
      title="选择考试"
      v-model="selExam.visible"
      width="1200px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <ChooseExam @callbackMethod="closeExam" :ids="selExam.examIds" />
    </a-modal>
  </a-spin>
</template>

<script>
import ChooseCourse from "../element/chooseCourse.vue";
import ChooseExam from "../element/chooseExam.vue";
let _this;
export default {
  name: "trigger",
  props: {
    id: String,
    dataType: Number,
    projectId: String,
  },
  data() {
    let checkNum = (rule, value, callback) => {
      if (value) {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      info: {
        autoCreate: true,
        autoAudit: true,
        type: 1,
        targetIds: [],
      },
      rules: {
        type: [
          { required: true, message: "请选择触发类型", trigger: "change" },
        ],
        value: [
          { required: true, message: "请输入触发值", trigger: "blur" },
          { validator: checkNum, trigger: "change" },
        ],
      },
      courses: {
        columns: [
          {
            dataIndex: "name",
            align: "left",
            title: "课程名称",
            ellipsis: true,
          },
          {
            dataIndex: "typeName",
            align: "left",
            title: "所属分类",
            ellipsis: true,
          },
          {
            dataIndex: "courseCredit",
            align: "center",
            title: "学分",
            width: 80,
          },
          {
            dataIndex: "hourCount",
            align: "center",
            title: "学时",
            width: 80,
          },
          {
            key: "action",
            align: "center",
            title: "操作",
            width: 80,
            scopedSlots: { customRender: "action" },
          },
        ],
        datas: [],
      },
      examinations: {
        columns: [
          {
            dataIndex: "testName",
            align: "left",
            title: "考试名称",
            ellipsis: true,
          },
          {
            dataIndex: "typeName",
            align: "left",
            title: "考试类型",
            ellipsis: true,
          },
          {
            dataIndex: "questionsCount",
            align: "center",
            title: "题目数量",
            width: 80,
          },
          {
            dataIndex: "passLine",
            align: "center",
            title: "及格分数",
            width: 80,
          },
          {
            key: "action",
            align: "center",
            title: "操作",
            width: 80,
            scopedSlots: { customRender: "action" },
          },
        ],
        datas: [],
      },
      courseScope: [],
      selCourse: {
        visible: false,
        courseIds: [],
      },
      selExam: {
        visible: false,
        examIds: [],
      },
    };
  },
  components: {
    ChooseCourse,
    ChooseExam,
  },
  mounted() {
    _this = this;
    _this.getTrigger();
  },
  methods: {
    typeChanged() {
      _this.info.targetIds = [];
      _this.info.value = null;
      _this.courses.datas = [];
      _this.examinations.datas = [];
      _this.courseScope = [];
    },
    getTrigger() {
      _this.isloading = true;
      if (_this.projectId) {
        _this.$api.project
          .getTrigger(_this.projectId, _this.id, _this.dataType)
          .then((res) => {
            if (res.errorCode == _this.$msg.responseCode.success) {
              if (res.data) {
                _this.info = { ...res.data.trigger };
                if (res.data.courses) {
                  _this.courses = [...res.data.courses];
                }
                if (res.data.examinations) {
                  _this.examinations = [...res.data.examinations];
                }
              }
            } else {
              _this.$message.error(res.errorMsg);
            }
            _this.isloading = false;
          })
          .catch(() => {
            _this.isloading = false;
          });
      } else {
        _this.$api.examination
          .getTrigger(_this.id, _this.dataType)
          .then((res) => {
            if (res.errorCode == _this.$msg.responseCode.success) {
              if (res.data && res.data.trigger) {
                _this.info = {
                  ...res.data.trigger,
                  autoCreate:
                    res.data.trigger.autoCreate != null
                      ? _this.$commen.changeIntBoolean(
                          res.data.trigger.autoCreate
                        )
                      : false,
                  autoAudit:
                    res.data.trigger.autoAudit != null
                      ? _this.$commen.changeIntBoolean(
                          res.data.trigger.autoAudit
                        )
                      : false,
                };
                if (res.data.courses) {
                  _this.courses.datas = res.data.courses;
                }
                if (res.data.examinations) {
                  _this.examinations.datas = [...res.data.examinations];
                }
                if (res.data.courseScope) {
                  _this.courseScope = [...res.data.courseScope];
                }
              }
            } else {
              _this.$message.error(res.errorMsg);
            }
            _this.isloading = false;
          })
          .catch(() => {
            _this.isloading = false;
          });
      }
    },
    selectCourse() {
      if (_this.info.type == 2) {
        _this.selCourse.courseIds = _this.info.targetIds;
      } else {
        _this.selCourse.courseIds = _this.courseScope;
      }
      _this.selCourse.visible = true;
    },
    selectExam() {
      _this.selExam.examIds = _this.info.targetIds;
      _this.selExam.visible = true;
    },
    removeCourse(id) {
      if (_this.info.type == 2) {
        for (let i = 0; i < _this.info.targetIds.length; i++) {
          if (_this.info.targetIds[i] == id) {
            _this.info.targetIds.splice(i, 1);
            break;
          }
        }
      } else {
        for (let i = 0; i < _this.courseScope.length; i++) {
          if (_this.courseScope[i] == id) {
            _this.courseScope.splice(i, 1);
            break;
          }
        }
      }
      let datas = [..._this.courses.datas];
      for (let i = 0; i < datas.length; i++) {
        if (datas[i].id == id) {
          datas.splice(i, 1);
          break;
        }
      }
      _this.courses.datas = [...datas];
    },
    removeExam(id) {
      for (let i = 0; i < _this.info.targetIds.length; i++) {
        if (_this.info.targetIds[i] == id) {
          _this.info.targetIds.splice(i, 1);
          break;
        }
      }
      let datas = [..._this.examinations.datas];
      for (let i = 0; i < datas.length; i++) {
        if (datas[i].id == id) {
          datas.splice(i, 1);
          break;
        }
      }
      _this.examinations.datas = [...datas];
    },
    closeCourse(courseIds) {
      if (_this.info.type == 2) {
        _this.info.targetIds = courseIds.split(",");
      } else {
        _this.courseScope = courseIds.split(",");
      }
      _this.selCourse.visible = false;
      _this.getCourseList();
    },
    closeExam(examIds) {
      _this.info.targetIds = examIds.split(",");
      _this.selExam.visible = false;
      _this.getExamList();
    },
    getCourseList() {
      let courseIds;
      if (_this.info.type == 2) {
        courseIds = _this.info.targetIds.join();
      } else {
        courseIds = _this.courseScope.join();
      }
      _this.$api.course.getCoursesByIds(courseIds).then((res) => {
        if (res.errorCode == _this.$msg.responseCode.success) {
          _this.courses.datas = res.data;
        }
      });
    },
    getExamList() {
      _this.$api.examination
        .getExamListByIds(_this.info.targetIds.join())
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.examinations.datas = res.data;
          }
        });
    },
    handleSubmit() {
      _this.isloading = true;
      if (_this.projectId) {
        // this.$api.project
        //   .setTrigger(
        //     this.projectId,
        //     this.id,
        //     this.dataType,
        //     this.info.type,
        //     values.value,
        //     this.info.targetIds ? this.info.targetIds.join(",") : ""
        //   )
        //   .then((res) => {
        //     this.isloading = false;
        //     if (res.errorCode == this.$msg.responseCode.success) {
        //       this.$message.success(res.errorMsg);
        //       this.$emit("callbackMethod", true);
        //     } else {
        //       this.$message.error(res.errorMsg);
        //     }
        //   })
        //   .catch(() => {
        //     this.isloading = false;
        //   });
      } else {
        let trigger = {
          type: _this.info.type,
          value: _this.info.value,
          targetIds: _this.info.targetIds,
        };
        if (_this.info.autoCreate != null) {
          trigger.autoCreate = _this.$commen.changeBooleanInt(
            _this.info.autoCreate
          );
        }
        if (_this.info.autoAudit != null) {
          trigger.autoAudit = _this.$commen.changeBooleanInt(
            _this.info.autoAudit
          );
        }
        _this.$api.examination
          .setTrigger(
            _this.id,
            _this.dataType,
            trigger,
            _this.courseScope.join()
          )
          .then((res) => {
            if (res.errorCode == _this.$msg.responseCode.success) {
              _this.$message.success(res.errorMsg);
              _this.$emit("callbackMethod");
            } else {
              _this.$message.error(res.errorMsg);
            }
            _this.isloading = false;
          })
          .catch(() => {
            _this.isloading = false;
          });
      }
    },
  },
};
</script>
<style>
</style>